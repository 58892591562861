<template>
  <div class="relative">
    <div class="vx-navbar-wrapper navbar-full p-0">
      <vs-navbar class="navbar-custom navbar-skelton" :class="navbarClasses" :style="navbarStyle" :color="navbarColor">
        <div class="flex flex-wrap items-center justify-between w-full">
          <div class="flex">
            <div class="cursor-pointer flex flex-wrap items-center mr-8 site-logo" v-if="Object.keys(merchant).length === 0">
              <img v-if="appName ==='FlipPay'" src="@/assets/images/logo/flippay-logo.svg" alt="Flippay" class=""/>
              <img v-else-if="appName==='RelloPay'" src="@/assets/images/logo/rellopay-logo.svg" alt="Rellopay" class=""/>
            </div>
            <div class="flex flex-col justify-center" v-if="Object.keys(merchant).length">
              <h4 class="mb-1" style="font-size: 0.875rem;color: #050540;font-weight: 700;">
                {{ merchant.companyName }}
              </h4>
              <div style="font-size: 14px;color: #828282;">
                <a :href="formattedUrl(merchant.website)" target="_blank">{{ merchant.website }}</a>
                <p>{{ merchant.phone }}</p>
                <a :href="`mailto:${merchant.email}`">{{ merchant.email }}</a>
              </div>
            </div>
          </div>

          <div class="text-center flex items-center partner-navbar onboarding-navbar">
            <ul>
              <li><a :href="helpSrc" class="" target="_blank">Help</a></li>
              <li><a :href="`mailto:${contactUs}`" target="_blank" class="">Contact us</a></li>
            </ul>
          </div>
          <div class="text-center flex items-center">
            <profile-drop-down />
          </div>
        </div>
      </vs-navbar>
    </div>
  </div>
</template>

<script>
import ProfileDropDown from "./components/ProfileDropDown.vue";

export default {
  name: "the-navbar-horizontal",
  data() {
    return {
      serverUrl: process.env.VUE_APP_API_URL,
      appName: process.env.VUE_APP_NAME ||"FlipPay",
      helpSrc: process.env.VUE_APP_NAME === "FlipPay" ? "https://support.flippay.com.au/knowledge" : "https://support.rellopay.co/knowledge",
      contactUs: process.env.VUE_APP_NAME === "FlipPay" ? "onboard@flippay.com.au" : "onboard@rellopay.co",
    };
  },
  props: {
    logo: { type: String },
    navbarType: {
      type: String,
      required: true
    }
  },
  components: {
    ProfileDropDown
  },
  methods: {
    formattedUrl(url) {
      if (!url) return "";
      return url.includes("http") ? url : `https://${url}`;
    }
  },
  mounted() {},
  computed: {
    merchant() {
      return localStorage.getItem("merchant")
        ? JSON.parse(localStorage.getItem("merchant"))
        : "";
    },
    navbarColor() {
      let color = "#fff";
      if (this.navbarType === "sticky") color = "#f7f7f7";
      else if (this.navbarType === "static") {
        if (this.scrollY < 50) {
          color = "#f7f7f7";
        }
      }

      this.isThemedark === "dark"
        ? color === "#fff"
          ? (color = "#10163a")
          : (color = "#262c49")
        : null;

      return color;
    },
    isThemedark() {
      return this.$store.state.theme;
    },
    navbarStyle() {
      return this.navbarType === "static"
        ? { transition: "all .25s ease-in-out" }
        : {};
    },
    navbarClasses() {
      return this.scrollY > 5 && this.navbarType === "static"
        ? null
        : "d-theme-dark-light-bg ";
    },
    scrollY() {
      return this.$store.state.scrollY;
    },
    verticalNavMenuWidth() {
      return this.$store.state.verticalNavMenuWidth;
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    }
  }
};
</script>

<style>
.vs-navbar.navbar-skelton {
    padding: .8rem 2.2rem;
}

.vs-navbar {
    height: auto !important;
}

@media (max-width: 576px) {
  .vs-navbar {
    padding: 0.625rem 1rem!important;
  }
}
</style>